import React, { useEffect } from 'react';
import HeaderSection from './HeaderSection';
import solution from '../../Assets/home/Solution.svg';
import Footer from '../../Components/Footer/Footer';
import ZamindarAppSection from './ZamindarAppSection';
import MidSection from './MidSection';
import FeaturesSection from './FeaturesSection';
import Backers from './Backers';

const HomePage = () => {

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }, 100);
  }, []);

  return (
    <>
      <div>
        <HeaderSection />
        <div id="zamindarAppSection" className='mt-4'>
          <ZamindarAppSection />
        </div>
        <div className='mb-6'>
          <MidSection />
        </div>
        <div className='mb-6 mt-20'>
          <h1 className='text-center font-extrabold text-darkGreen text-2xl sm:text-5xl'>
            Features <span className='text-black'>&</span> Services
          </h1>
          <FeaturesSection />
        </div>
        <div className='p-5 mt-4'>
          <h1 className='text-center font-extrabold text-black text-4xl sm:text-6xl'>
            Our <span className='text-darkGreen'>Solution</span>
          </h1>
          <div className='flex justify-center items-center p-5 mt-4 mb-4'>
            <img
              src={solution}
              className='w-full sm:w-2/4 mx-auto transition-transform duration-300 transform hover:-translate-y-3'
              alt=""
            />
          </div>
        </div>
        <div className='p-5 mt-4'>
          <h1 className='text-center font-extrabold text-black text-3xl sm:text-4xl'>
            Our <span className='text-darkGreen'>Backers</span>
          </h1>
          <Backers />
        </div>
        <Footer />
      </div>
    </>
  );
};

export default HomePage;
