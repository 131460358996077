import React from 'react'
import store from '../../Assets/partner/store.svg'
import data from '../../Assets/partner/data-driven.svg'
import file from '../../Assets/partner/file-bar-chart.svg'
import laptop from '../../Assets/partner/laptop.svg'
import status from '../../Assets/partner/status.svg'
import truck from '../../Assets/partner/truck.svg'
import img from '../../Assets/partner/zxczx 1.svg'

const BenefitsPage = () => {
    return (
        <>
            <div className="flex flex-col lg:flex-row w-full min-h-screen">
                {/* Left Section */}
                <div className="w-full lg:w-2/3 p-8 lg:p-16">
                    <h1 className="font-semibold text-2xl lg:text-4xl mb-6 lg:mb-10">Benefits</h1>
                    <div className="flex items-center gap-4 lg:gap-6 mb-6 lg:mb-8">
                        <img src={store} alt="" className="w-8 h-8 lg:w-10 lg:h-10" />
                        <div>
                            <h1 className="font-semibold text-sm lg:text-md">50,000+ retailers</h1>
                            <p className="text-xs">
                                Make your products available to 50,000+ retailers instantly
                            </p>
                        </div>
                    </div>
                    <div className="flex items-center gap-4 lg:gap-6 mb-6 lg:mb-8">
                        <img src={data} alt="" className="w-8 h-8 lg:w-10 lg:h-10" />
                        <div>
                            <h1 className="font-semibold text-sm lg:text-md">Data Driven Warehousing</h1>
                            <p className="text-xs">
                                Store your products at our sophisticated warehouses
                            </p>
                        </div>
                    </div>
                    <div className="flex items-center gap-4 lg:gap-6 mb-6 lg:mb-8">
                        <img src={truck} alt="" className="w-8 h-8 lg:w-10 lg:h-10" />
                        <div>
                            <h1 className="font-semibold text-sm lg:text-md">Super Fast Delivery</h1>
                            <p className="text-xs">
                                Get your goods delivered within 24 hours via our delivery fleet
                            </p>
                        </div>
                    </div>
                    <div className="flex items-center gap-4 lg:gap-6 mb-6 lg:mb-8">
                        <img src={status} alt="" className="w-8 h-8 lg:w-10 lg:h-10" />
                        <div>
                            <h1 className="font-semibold text-sm lg:text-md">Multi-Regional Status</h1>
                            <p className="text-xs">
                                Acquire the status of a multi-regional brand
                            </p>
                        </div>
                    </div>
                    <div className="flex items-center gap-4 lg:gap-6 mb-6 lg:mb-8">
                        <img src={file} alt="" className="w-8 h-8 lg:w-10 lg:h-10" />
                        <div>
                            <h1 className="font-semibold text-sm lg:text-md">Boost Sales</h1>
                            <p className="text-xs">
                                Increase your sales by marketing your goods on our digital assets
                            </p>
                        </div>
                    </div>
                    <div className="flex items-center gap-4 lg:gap-6 mb-6 lg:mb-8">
                        <img src={laptop} alt="" className="w-8 h-8 lg:w-10 lg:h-10" />
                        <div>
                            <h1 className="font-semibold text-sm lg:text-md">New Markets</h1>
                            <p className="text-xs">
                                Enter new markets through our business growth services
                            </p>
                        </div>
                    </div>
                </div>

                {/* Right Section */}
                <div className="w-full lg:w-1/3 p-5 bg-green">
                    <img src={img} alt="" className="w-full h-full rounded-xl object-cover" />
                </div>
            </div>
        </>
    )
}

export default BenefitsPage
