import React from 'react'

const Form = ({ heading, text }) => {
    return (
        <>
            <section className="relative" style={{ backgroundColor: '#D5F5E3' }}>
                <div className="container px-5 py-24 mx-auto flex sm:flex-nowrap flex-wrap">
                    <div className="lg:w-2/3 md:w-1/2 rounded-lg overflow-hidden sm:mr-10 p-10 flex items-center justify-start relative">
                        <div className='w-full sm:w-80'>
                            <h1 className='text-xl text-center sm:text-left sm:text-3xl font-semibold mb-3'>{heading}</h1>
                            <p className='text-center sm:text-left font-normal text-sm'>{text}</p>
                        </div>
                    </div>
                    <div className="lg:w-1/3 md:w-1/2 flex flex-col md:ml-auto w-full md:py-8 mt-8 md:mt-0">
                        <div className="relative mb-4">
                            <input type="text" id="name" name="name" placeholder='Full Name' className="w-full placeholder:text-gray-400 text-xs bg-white rounded border focus:border-darkGreen focus:ring-darkGreen outline-none py-3 px-3" />
                        </div>
                        <div className="relative mb-4">
                            <input type="text" id="number" name="number" placeholder='Mobile Number' className="w-full bg-white rounded border placeholder:text-gray-400 text-xs focus:border-darkGreen focus:ring-darkGreen outline-none py-3 px-3" />
                        </div>
                        <div className="relative mb-4">
                            <textarea id="message" name="message" placeholder='Message' className="w-full bg-white rounded border placeholder:text-gray-400 text-xs resize-none focus:border-darkGreen focus:ring-darkGreen h-32 outline-none py-1 px-3"></textarea>
                        </div>
                        <button className="text-white bg-darkGreen py-2 px-6 text-sm rounded">Submit</button>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Form
