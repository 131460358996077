import React from 'react'
import whatsapp from '../../Assets/home/whatsapp.svg';
import twitter from '../../Assets/home/twitter.svg';
import linkedin from '../../Assets/home/linkedin.svg';
import instagram from '../../Assets/home/instagram.svg';
import facebook from '../../Assets/home/facebook.svg';
import huawei from '../../Assets/footer/huawei - Copy 1.svg';
import playstore from '../../Assets/footer/playstore 1.svg';
import { useNavigate } from 'react-router-dom';

const Footer = () => {
  const navigate = useNavigate();
  const icons = [
    { src: whatsapp, alt: '', link: 'https://www.whatsapp.com' },
    { src: twitter, alt: '', link: 'https://www.twitter.com' },
    { src: linkedin, alt: '', link: 'https://www.linkedin.com' },
    { src: instagram, alt: '', link: 'https://www.instagram.com' },
    { src: facebook, alt: '', link: 'https://www.facebook.com' },
  ];

  return (
    <>
      <footer className=" bg-black text-white">
        <div className='flex flex-col sm:flex-row sm:justify-between gap-10 p-14'>
          <div className="flex justify-between w-full mt-2">
            <div className='mt-2 w-full text-center sm:text-left'>
              <p onClick={() => { navigate('/') }} className="sm:text-2xl text-md font-semibold text-green cursor-pointer">Zamindar <span className='text-white'>Technologies</span> </p>
              <p className="mt-1 text-xs">Revolutionizing supply & production in farm</p>
            </div>
          </div>
          <div className='flex flex-col sm:flex-row sm:justify-between w-full'>
            <div className="text-sm text-center sm:text-left">
              <h2 className=" text-white tracking-widest font-semibold text-sm mb-3">Rules</h2>
              <nav className="list-none">
                <li>
                  <a className="cursor-pointer hover:text-darkGreen">Careers</a>
                </li>
                <li>
                  <a className="cursor-pointer hover:text-darkGreen">Privacy & Policy</a>
                </li>
                <li>
                  <a className="cursor-pointer hover:text-darkGreen">Terms & Conditions</a>
                </li>
              </nav>
            </div>
            <div className="text-sm text-center sm:text-left">
              <h2 className=" text-white tracking-widest font-semibold text-sm mb-3 mt-10 sm:mt-0">Download Now</h2>
              <nav className="list-none">
                <li>
                  <a>Become a Zamindar Partner & Start Boosting <br />
                    your earning now</a>
                </li>
                <li className='flex gap-2 mt-2 justify-center sm:justify-start'>
                  <a href="https://play.google.com/store/apps/details?id=com.zamindartech.zamindarpartner" target='_blank'> <img src={playstore} alt="playstore" className='w-8 h-8 cursor-pointer hover:bg-darkGreen hover:rounded-full' /></a>
                  <a href="https://appgallery.huawei.com/app/C107207693" target='_blank'><img src={huawei} alt="huawei" className='w-8 h-8 cursor-pointer hover:bg-darkGreen hover:rounded-full' /></a>
                </li>
              </nav>
            </div>
          </div>
        </div>

        <div className="bg-darkGreen flex flex-col sm:flex-row text-center sm:text-left  sm:justify-between">
          <div className="text-white p-4 text-xs mx-3">
            Copyright © 2024 Zamindar Technologies (Private) Limited
          </div>
          <div className='text-center text-md flex justify-center items-center sm:mr-8 gap-1 mb-4 sm:mb-0'>
            {icons.map((icon, index) => (
              <a href={icon.link} key={index} target="_blank" rel="noopener noreferrer">
                <div className='flex justify-center items-center cursor-pointer'>
                  <img src={icon.src} alt='' className='w-8 h-8 hover:bg-black hover:rounded-full' />
                </div>
              </a>
            ))}
          </div>
        </div>
      </footer>
    </>
  )
}

export default Footer
