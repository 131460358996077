import React, { useEffect } from 'react'
import PartnerHeaderSection from './PartnerHeaderSection'
import Footer from '../../Components/Footer/Footer'
import BenefitsPage from './BenefitsPage'
import FormSection from './FormSection'

const Partner = () => {

    useEffect(() => {
        setTimeout(() => {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }, 100);
    }, []);

    return (
        <>
            <div>
                <PartnerHeaderSection />
                <div id='benefits'>
                    <BenefitsPage />
                </div>
                <FormSection />
                <Footer />
            </div>
        </>
    )
}

export default Partner
