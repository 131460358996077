import React, { useState } from 'react';
import phone from '../../Assets/product/phone.png';
import store from '../../Assets/product/store.svg';
import download from '../../Assets/product/download.svg';
import options from '../../Assets/product/options.svg';
import items from '../../Assets/product/items.svg';
import phone2 from '../../Assets/product/phone2.png';
import phone3 from '../../Assets/product/phone3.png';
import phone4 from '../../Assets/product/phone4.png';

const ProductMiddleSection = () => {
    const [selected, setSelected] = useState(phone);
    const [activeLabel, setActiveLabel] = useState();
    const [isAnimating, setIsAnimating] = useState(false);

    const Images = [
        { label: 'Download Zamindar App', imgSrc: phone, icon: download },
        { label: 'Register Your Shop', imgSrc: phone2, icon: store },
        { label: 'Select The Option', imgSrc: phone3, icon: options },
        { label: 'Select The Items For Purchase', imgSrc: phone4, icon: items },
    ];

    const handleClick = (imgSrc, label) => {
        if (imgSrc !== selected) {
            setIsAnimating(true);
            setTimeout(() => {
                setSelected(imgSrc);
                setIsAnimating(false);
            }, 200);
        }
        setActiveLabel(label);
    };

    return (
        <>
            <div className='grid grid-cols-1 sm:grid-cols-2 justify-items-center p-14'>
                <div className='relative overflow-hidden'>
                    <img
                        src={selected}
                        alt=""
                        className={`w-full lg:w-3/4 mx-auto transition-all duration-500 ease-in-out ${isAnimating ? 'opacity-0 transform translate-x' : 'opacity-100 transform translate-x-0'
                            }`}
                    />
                </div>

                <div className='flex flex-col gap-3 justify-center mt-10 sm:mt-0'>
                    <h1 className='font-semibold text-center w-full sm:text-left text-2xl sm:text-4xl'>How It Works</h1>
                    {Images.map((image, index) => (
                        <div
                            key={index}
                            className={`flex gap-3 cursor-pointer p-3 mt-2 rounded transition-all duration-500 ease-in-out ${activeLabel === image.label ? 'bg-lightBlue' : ''
                                }`}
                            onClick={() => handleClick(image.imgSrc, image.label)}
                        >
                            <img src={image.icon} className='h-5 w-5' />
                            <p className='text-xs sm:text-sm font-normal'>{image.label}</p>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};

export default ProductMiddleSection;