import React from 'react'
import truck from '../../Assets/home/truck.svg'
import user from '../../Assets/home/userGroup.svg'
import location from '../../Assets/home/location_on.svg'

const MidSection = () => {
    return (
        <>
            <div className='bg-darkGreen text-white grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5 p-9 justify-items-center'>
                <div className='flex justify-center items-center flex-col'>
                    <img src={location} alt="" className='h-16 w-16 mb-3' />
                    <h1 className='text-3xl sm:text-5xl text-center sm:text-left font-bold'>3</h1>
                    <p className='text-sm text-center sm:text-left mx-1'>Cities Served</p>
                </div>
                <div className='flex justify-center items-center flex-col'>
                    <img src={user} alt="" className='h-16 w-16 mb-3' />
                    <h1 className='text-3xl sm:text-5xl text-center sm:text-left font-bold'>5000+</h1>
                    <p className='text-sm text-center sm:text-left mx-1'>Retailers Enabled</p>
                </div>
                <div className='flex justify-center items-center flex-col'>
                    <img src={truck} alt="" className='h-16 w-16 mb-3' />
                    <h1 className='text-3xl sm:text-5xl text-center sm:text-left font-bold'>50,000+</h1>
                    <p className='text-sm text-center sm:text-left mx-1'>Order Received</p>
                </div>
            </div>
        </>
    )
}

export default MidSection;
