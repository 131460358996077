import React from 'react'
import Form from '../../Components/Atomic/Form'

const FormSection = () => {
  return (
    <>
      <Form
        heading='Become a partner'
        text='If you want to work with us or have questions, leave us a message, we will get back to you in next 24 hours.' />
    </>
  )
}

export default FormSection
