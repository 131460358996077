import React from 'react'
import Form from '../../Components/Atomic/Form'

const ProductForm = () => {
    return (
        <>
            <Form
                heading={
                    <>
                        Join the network <br />of 50,000+ retailers
                    </>
                }
                text={
                    <>
                        Share your details with a short message, and we will get in touch within 24 hours
                    </>
                }
            />
        </>
    )
}

export default ProductForm
