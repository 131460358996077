import React from 'react'
import TTLogo from '../../Assets/home/T-logo.png'
import google from '../../Assets/home/google.png'
import Accelerate from '../../Assets/home/AccelerateProsperity.png'
import plan9 from '../../Assets/home/plan9.png'
import BackerCards from '../../Components/Atomic/BackerCards'

const Backers = () => {
    return (
        <>
            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 p-10 sm:p-16 justify-items-center'>
                <BackerCards
                    img={google} />
                <BackerCards
                    img={plan9} />
                <BackerCards
                    img={Accelerate} />
                <BackerCards
                    img={TTLogo} />
            </div>
        </>
    )
}

export default Backers
