import React from 'react';
import Header from '../../Components/Header/Header';

const ProductHeader = () => {
  const scrollToProduct = () => {
    const product = document.getElementById('product');
    if (product) {
      product.scrollIntoView({ behavior: 'smooth' });
    }
  };
  return (
    <>
      <Header
        heading={
          <div className=' leading-[4.3rem]'>
            Simplify <br /> your <br /> business
          </div>
        }
        text={
          <>
            Easily access thousands of products <br /> and order as per your convenience
          </>
        }
        scroll={scrollToProduct}
      />
    </>
  );
};

export default ProductHeader;
