import React from 'react'
import store from '../../Assets/product/store.svg'
import filestack from '../../Assets/product/filestack.svg'
import badge from '../../Assets/product/badge.svg'
import gem from '../../Assets/product/gem.svg'

const ProductCardSection = () => {
    return (
        <>
            <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-10 sm:gap-3 p-14 justify-items-center' style={{ backgroundColor: '#FAFAFF' }}>
                <div className='flex flex-col justify-center items-center text-center'>
                    <img src={store} alt="" className='h-9 w-9 mb-3' />
                    <h1 className='text-xl font-semibold'>One Stop Solution</h1>
                    <p className='text-sm mt-2'>Choose from thousands of <br /> products</p>
                </div>
                <div className='flex flex-col justify-center items-center text-center'>
                    <img src={filestack} alt="" className='h-9 w-9 mb-3' />
                    <h1 className='text-xl font-semibold'>Free 24 hr Delivery</h1>
                    <p className='text-sm mt-2'>Stock your shelves within 24 <br />hours</p>
                </div>
                <div className='flex flex-col justify-center items-center text-center'>
                    <img src={badge} alt="" className='h-9 w-9 mb-3' />
                    <h1 className='text-xl font-semibold'>Competitive Pricing</h1>
                    <p className='text-sm mt-2'>Compare prices and purchase on <br /> competitive rates</p>
                </div>
                <div className='flex flex-col justify-center items-center text-center'>
                    <img src={gem} alt="" className='h-9 w-9 mb-3' />
                    <h1 className='text-xl font-semibold'>Quick, Easy & Reliable</h1>
                    <p className='text-sm mt-2'>Technology and teams to  simplify <br /> the process</p>
                </div>
            </div>
        </>
    )
}

export default ProductCardSection
