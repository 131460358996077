import React from 'react'
import Header from '../../Components/Header/Header'

const PartnerHeaderSection = () => {
    const scrollToBenefits = () => {
        const benefitsSection = document.getElementById('benefits');
        if (benefitsSection) {
            benefitsSection.scrollIntoView({ behavior: 'smooth' });
        }
    };
    return (
        <>
            <Header
                heading={<>Become a Zamindar <br /> Partner</>}
                text='Reach small businesses all over MENAP through our supercharged retail network'
                scroll={scrollToBenefits}
            />
        </>
    )
}

export default PartnerHeaderSection
