import React from 'react';
import whatsapp from '../../Assets/home/whatsapp.svg';
import twitter from '../../Assets/home/twitter.svg';
import linkedin from '../../Assets/home/linkedin.svg';
import instagram from '../../Assets/home/instagram.svg';
import facebook from '../../Assets/home/facebook.svg';
import './Header.css';
import { useNavigate } from 'react-router-dom';
import DownScroll from '../Atomic/DownScroll';

const Header = ({ heading, text, scroll }) => {
  const navigate = useNavigate();
  const icons = [
    { src: whatsapp, alt: '', link: 'https://www.whatsapp.com' },
    { src: twitter, alt: '', link: 'https://www.twitter.com' },
    { src: linkedin, alt: '', link: 'https://www.linkedin.com' },
    { src: instagram, alt: '', link: 'https://www.instagram.com' },
    { src: facebook, alt: '', link: 'https://www.facebook.com' },
  ];

  return (
    <div className="header w-full h-auto lg:h-screen text-white">
      <div className="flex justify-between navbar mb-8 md:mb-20 px-4 md:px-10">
        <h1
          onClick={() => navigate('/')}
          className="font-bold text-4xl md:text-5xl cursor-pointer mt-4"
        >
          زمیندار
        </h1>
        <div className="flex justify-center items-center mt-6 sm:mt-0 gap-1">
          {icons.map((icon, index) => (
            <a
              href={icon.link}
              key={index}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="flex justify-center items-center cursor-pointer">
                <img
                  src={icon.src}
                  alt=''
                  className="w-7 md:w-8 h-7 md:h-8 hover:bg-darkGreen hover:rounded-full"
                />
              </div>
            </a>
          ))}
        </div>
      </div>
      <div className="flex flex-col mt-16 sm:mt-0 gap-8 w-full text-center sm:text-left md:w-[38rem] px-4 md:px-28">
        <span className="font-semibold text-4xl md:text-6xl w-full xl:w-[30rem]">
          {heading}
        </span>
        <p className="text-lg">{text}</p>
      </div>
      <div className='flex justify-center sm:justify-start '>
        <button
          onClick={() => navigate('/partner')}
          className="bg-white text-black font-medium px-5 py-4 mx-4 md:mx-28 mt-7 mb-14 rounded-full text-xs"
        >
          Start selling with zamindar
        </button>
      </div>
      <div className="hidden lg:block items-center absolute left-[50%] lg:bottom-4 xl:bottom-10">
        <DownScroll onClick={scroll} />
      </div>
    </div>
  );
};

export default Header;