import React from 'react'
import Header from '../../Components/Header/Header'

const HeaderSection = () => {
  const scrollToZamindarApp = () => {
    const zamindarAppSection = document.getElementById('zamindarAppSection');
    if (zamindarAppSection) {
      zamindarAppSection.scrollIntoView({ behavior: 'smooth' });
    }
  };
  return (
    <>
      <Header
        heading={<>Digitizing traditional <br /> trade</>}
        text='Enabling 10 million SMEs across Pakistan'
        scroll={scrollToZamindarApp}
      />
    </>
  )
}

export default HeaderSection
