import React from 'react'
import team from '../../Assets/home/team.svg'
import tracking from '../../Assets/home/tracking.svg'
import stats from '../../Assets/home/statistics.svg'
import operation from '../../Assets/home/operation.svg'
import Cards from '../../Components/Atomic/Cards'

const FeaturesSection = () => {
    return (
        <>
            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 p-10 sm:p-16 justify-items-center'>
                <Cards
                    img={team}
                    text='Direct Access' />
                <Cards
                    img={tracking}
                    text='Visibility of Supplies' />
                <Cards
                    img={operation}
                    text='No Operational Burden' />
                <Cards
                    img={stats}
                    text='Analytics and Insight' />
            </div>
        </>
    )
}

export default FeaturesSection
