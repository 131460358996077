import React, { useEffect } from 'react'
import ProductHeader from './ProductHeader'
import Footer from '../../Components/Footer/Footer'
import ProductCardSection from './ProductCardSection';
import ProductForm from './ProductForm';
import ProductMiddleSection from './ProductMiddleSection';

const ProductPage = () => {

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }, 100);
  }, []);

  return (
    <>
      <ProductHeader />
      <div id='product'>
        <ProductCardSection />
      </div>
      <div>
        <ProductMiddleSection />
      </div>
      <div>
        <ProductForm />
      </div>
      <Footer />
    </>
  )
}

export default ProductPage
