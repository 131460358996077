import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './Pages/Homepage/HomePage';
import Partner from './Pages/Partner/Partner';
import ProductPage from './Pages/Product/ProductPage';
import { SpeedInsights } from '@vercel/speed-insights/react';

function App() {
  return (
    <>
      <SpeedInsights />
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/partner" element={<Partner />} />
          <Route path="/product" element={<ProductPage />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
