import React from 'react'
import phone from '../../Assets/product/phone3.png'
import bag from '../../Assets/home/shopping-bag.svg'
import arrow from '../../Assets/home/right-arrow.svg'
import { useNavigate } from 'react-router-dom'

const ZamindarAppSection = () => {
    const navigate = useNavigate();
    return (
        <>
            <div className='flex flex-col lg:flex-row justify-between items-center p-8 lg:p-16'>
                <div className='lg:w-1/3 text-center lg:text-left mb-10 lg:mb-0'>
                    <h1 className='font-semibold text-3xl mb-4'>
                        How we do it
                    </h1>
                    <p className='mb-6 text-sm'>
                        Zamindar is building a digital ecosystem to
                        empower community commerce across Pakistan.
                    </p>
                    <button className='bg-darkGreen cursor-default flex m-auto sm:m-0 lg:justify-start items-center gap-3 px-6 text-white py-4 mt-7 rounded-xl text-sm'>
                        <img src={bag} alt="" className='w-7 h-7' />
                        E-Commerce <img src={arrow} alt="" />
                    </button>
                </div>
                <div className='mb-10 lg:mb-0'>
                    <img src={phone} alt="" className='w-full lg:w-3/4' />
                </div>
                <div className='lg:w-1/3 text-center lg:text-left'>
                    <h1 className='font-semibold text-3xl mb-4'>
                        Zamindar App
                    </h1>
                    <p className='mb-2 text-sm'>
                        The Zamindar App is the number one choice of
                        thousands of retailers for restocking their shops.
                        Its easy-to-use features allow retailers from across
                        Pakistan to browse through thousands of products,
                        make instant price comparisons, and get them
                        delivered the next day.
                    </p>
                    <button onClick={() => { navigate('/product') }} className='bg-darkGreen px-6 py-3 text-white mt-7 rounded-full text-sm'>
                        Read More
                    </button>
                </div>
            </div>
        </>
    )
}

export default ZamindarAppSection
