import React from 'react'
import vector from '../../Assets/home/Vector.svg';

const DownScroll = ({ onClick }) => {
    return (
        <>
            <img onClick={onClick} className='cursor-pointer' src={vector} alt='' />
        </>
    )
}

export default DownScroll
