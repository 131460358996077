import React from 'react'

const Cards = ({ img, text }) => {
    return (
        <>
            <div className='w-full'>
                <div className='flex justify-center flex-col items-center border rounded-xl font-medium shadow-md p-5 hover:bg-lightGreen'>
                    <img src={img} alt="" className='h-16 w-16 mb-3' />
                    <p className='text-xs'>{text}</p>
                </div>
            </div>
        </>
    )
}
export default Cards
