import React from 'react'
import './BackerCard.css'

const BackerCards = ({ img }) => {
    return (
        <>
            <div className='w-full'>
                <div className='flex justify-center items-center p-5 border rounded-xl shadow-md'>
                    <img src={img} alt="" className='logos' />
                </div>
            </div>
        </>
    )
}

export default BackerCards
